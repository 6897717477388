<template>
    <div>
        <div class="row text-right">
            <button id="export-transaction-button" class="mr-2 text-capitalize" :class="{ disabled: !transactions.length }">
            <download-excel
            class="mr-1"
                :data="transactions"
                :fields="csv_fields"
                type="csv"
                name="transactions.csv"
            >
                <file-icon size="1x" class="custom-class m-0"></file-icon>
            Export
            </download-excel>
          </button>
            <span v-if="!dateRangeBox" class="pointer" id="filter-transaction-box"
                  @click="toggleDateRangeBox()"><filter-icon size="1.3x" class="pt-1"></filter-icon>Filter</span>
            <span v-if="!searchBox" class="pointer ml-2" id="search-transaction-box"
                  @click="toggleSearchBox()"><search-icon size="1.3x" class="pt-1"></search-icon>Search</span>

            <div v-if="dateRangeBox" class="row flex-row-align-center">
                <div class="col-md-10 col-lg-10 col-xs-10 col-sm-10">
                    <date-picker @input="filterTransactionsDateRange" v-model="selectedDateRange" range value-type="format" format="YYYY-MM-DD" class="w-100 m-2" id="hello-test-filter"></date-picker>
                </div>
                <div class="col-md-2 col-lg-2 col-xs-2 col-sm-2 text-left">
                    <x-icon size="1.5x" class="pointer" @click="toggleDateRangeBox()"></x-icon>
                </div>
            </div>
            
            <div v-if="searchBox" class="row flex-row-align-center">
                <div class="col-md-10 col-lg-10 col-xs-10 col-sm-10">
                    <input type="text" class="w-100 p-2 m-2" placeholder="Search transaction reference..."
                           @input="searchTransaction()" v-model="searchTransactionQuery"
                           id="search-transaction-reference-input">
                </div>
                <div class="col-md-2 col-lg-2 col-xs-2 col-sm-2 text-left">
                    <x-icon size="1.5x" class="pointer" @click="toggleSearchBox()"></x-icon>
                </div>
            </div>          
        </div>
        <div class="main-content-header">
            <h4><strong>Transactions</strong></h4>
        </div>
        <div id="transaction-main-box">
            <div v-if="transactions.length>0">
                <div class="row"
                     v-for="(item,index) in transactions" :key="index">
                    <div class="col-md-12 col-lg-8 col-xs-12 col-sm-12">
                        <div class="row bg-white p-2 m-2 pointer" @click="expandTransaction(item)">
                            <div class="mb-1">
                                <small>{{convertDate(item.tx.status_dt)}}</small>
                            </div>
                            <h5>Transaction Ref : <strong>{{item.tx.tx_ref_num}}</strong></h5>
                            <div class="transfer-detail-header mb-1">
                                <h6 v-if="item.tx.status === 'good'"><strong>In Progress</strong></h6>
                                <h6 v-else-if="item.tx.status === 'done'"><strong>Transfer Done</strong></h6>
                                <h6 v-else-if="item.tx.status === 'invalid'" class="text-error"><strong>Invalid</strong>
                                </h6>
                            </div>
                            <div class="row">
                                <div class="col-md-12 col-xs-12 col-sm-12 col-lg-12">
                                    <div style="display: flex;flex-wrap: wrap;justify-content: space-between">
                                        <strong>To : {{item.receiver.name}}</strong>
                                        <strong>{{formatFiatPrice(item.tx.receive_amt)}}
                                            {{item.tx.receive_ccy}}</strong>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12 col-xs-12 col-sm-12 col-lg-12">
                                    <div style="display: flex;flex-wrap: wrap;justify-content: space-between">
                                        <small>Paid in crypto</small>
                                        <small>{{item.tx.crypto_amt}} {{item.tx.crypto_ccy}}</small>
                                    </div>
                                </div>
                            </div>
                            <div class="row" v-if="item.visible">
                                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 transfer-detail-stepper-col">

                                    <div class="transfer-detail-stepper-container">
                                        <div class="transfer-detail-stepper">
                                            <div class="v-stepper">
                                                <div class="circle"
                                                     :class="{'circle-complete' : item.tx.status === 'done' || item.tx.status === 'good'}"></div>
                                                <div class="first-line"
                                                     :class="{'line-complete' : item.tx.status === 'done' || item.tx.status === 'good'}"></div>
                                            </div>
                                            <div>
                                                <p class="ml-1"
                                                   :class="{'text-lightgrey' : item.tx.status !== 'done' && item.tx.status !== 'good'}">
                                                    You have made a transfer</p>
                                            </div>
                                        </div>
                                        <div class="transfer-detail-stepper">
                                            <div class="v-stepper">
                                                <div class="circle"
                                                     :class="{'circle-complete' : item.tx.status === 'done' || item.tx.status === 'good'}"></div>
                                                <div class="second-line"
                                                     :class="{'line-complete' : item.tx.status === 'done'}"></div>
                                            </div>
                                            <div>
                                                <p class="ml-1"
                                                   :class="{'text-lightgrey' : item.tx.status !== 'done' && item.tx.status !== 'good'}">
                                                    We have received your crypto ({{item.tx.crypto_ccy }}
                                                    {{item.tx.crypto_amt ||
                                                    "NA"}})</p>
                                            </div>
                                        </div>
                                        <div class="transfer-detail-stepper">
                                            <div class="v-stepper">
                                                <div class="circle"
                                                     :class="{'circle-complete' : item.tx.status === 'done'}"></div>
                                                <div class="third-line"
                                                     :class="{'line-complete' : item.tx.status === 'done'}"></div>
                                            </div>
                                            <div>
                                                <p class="ml-1" :class="{'text-lightgrey' : item.tx.status !== 'done'}">
                                                    We pay out {{item.tx.receive_ccy}}
                                                    {{formatFiatPrice(item.tx.receive_amt)}} to
                                                    {{item.receiver.name}}</p>
                                            </div>
                                        </div>
                                        <div class="transfer-detail-stepper">
                                            <div class="v-stepper">
                                                <div class="circle"
                                                     :class="{'circle-complete' : item.tx.status === 'done'}"></div>
                                            </div>
                                            <div>
                                                <p class="ml-1" :class="{'text-lightgrey' : item.tx.status !== 'done'}">
                                                    Recipient receive the money</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>

                </div>
            </div>
            <div class="row" v-else>
                <h5 class="p-2">No transactions found.</h5>
            </div>
        </div>


    </div>
</template>
<script>
    import Vue from 'vue';
    import {mapActions, mapGetters} from 'vuex';
    import {SearchIcon, ChevronDownIcon, XIcon, FilterIcon, FileIcon} from 'vue-feather-icons'
    import {CheckIcon} from 'vue-feather-icons'
    // date range npm depen
    import DatePicker from 'vue2-datepicker';
    import 'vue2-datepicker/index.css';
    
    // npm dependency
    import downloadExcel from "vue-json-excel";


    export default {
        components: {
            // CheckIcon,
            SearchIcon,
            XIcon,
            FilterIcon,
            DatePicker,
            FileIcon,
            downloadExcel
        },
        data() {
            return {
                toggleTransferDetail: false,
                transactions: [],
                searchTransactionQuery: "",
                selectedDateRange: [null, null],
                searchBox: false,
                dateRangeBox: false,

                debugConsole: false,
                csv_fields: {
                    "Crypto Currency": "tx.crypto_ccy",
                    "Crypto Amount": "tx.crypto_amt",
                    "Send Currency": "tx.send_ccy",
                    "Send Amount": "tx.send_amt",
                    "Receive Amount": "tx.receive_amt",
                    "Receiver Name": "receiver.name",
                    "Receiver Country": "receiver.country",
                    "Receiver City": "receiver.address_city",
                    "Bank Name": "receiver.bank_name",
                    "Account Holder": "receiver.acct_holder_name",
                    "Bank Account No.": "receiver.bank_acct_num",
                },
            }
        },
        computed: {
            ...mapGetters(["userDetailsGetter"]),

        },
        metaInfo: {
            title: 'TransCrypt - Dashboard Home | Send Money Faster with Cryptocurrency',
        },
        mounted() {
            if (this.debugConsole) console.log("Dashboard home Mounted");
            this.getAllTransactions();
        },

        methods: {
            ...mapActions([
                "getTransactions"
            ]),
            getAllTransactions() {
                //get transaction List
                this.getTransactions().then(res => {
                    if (this.debugConsole) console.log("get transaction details in dashboard homepage", res.data);

                    //get the transaction list
                    this.transactions = this.userDetailsGetter && this.userDetailsGetter.transactions

                    if (this.debugConsole) console.log("Transactions", this.transactions);

                }).catch(err => {
                    if (this.debugConsole) console.log("Get transaction details error in dashboard homepage", err);
                })
            },
            toggleTransferDetails() {
                this.toggleTransferDetail = !this.toggleTransferDetail;
            },
            expandTransaction(item) {

                let payload = {};
                payload.txReference = item.tx.tx_ref_num;
                payload.visible = !item.visible;

                this.$store.commit("EXPAND_TRANSACTION", payload);
            },
            searchTransaction() {
                let searchTransactionQuery = this.searchTransactionQuery.toUpperCase();
                this.transactions = this.userDetailsGetter && this.userDetailsGetter.transactions.filter(item => item.tx.tx_ref_num.includes(searchTransactionQuery));
            },
            filterTransactionsDateRange() {
                let [start, end] = this.selectedDateRange;
                let _start = new Date(start);
                let _end = new Date(end);
                if(this.userDetailsGetter && Object.keys(this.userDetailsGetter).length) {
                    this.transactions = this.userDetailsGetter.transactions.filter(item => {
                        let tx_dt = item.tx.status_dt.split('T')[0];
                        let date = new Date(tx_dt);
                        return (date >= _start && date <= _end);
                    });
                }
            },
            toggleSearchBox() {
                this.searchTransactionQuery = '';
                this.searchTransaction();
                this.searchBox = !this.searchBox;
            },
            toggleDateRangeBox() {
                this.selectedDateRange.length = 0;
                this.getAllTransactions();
                this.dateRangeBox = !this.dateRangeBox;
            }
        },
    }

</script>
<style scoped>

</style>